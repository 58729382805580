  import React, { useRef, useEffect } from 'react';
  import { graphql } from 'gatsby';
  import { StaticImage } from 'gatsby-plugin-image';
  import PropTypes from 'prop-types';
  import { Helmet } from 'react-helmet';
  import styled from 'styled-components';
  import { srConfig } from '@config';
  import sr from '@utils/sr';
  import { Layout } from '@components';
  import { usePrefersReducedMotion } from '@hooks';

  const StyledTableContainer = styled.div`
    margin: 100px -20px;

    @media (max-width: 768px) {
      margin: 50px -10px;
    }

    table {
      font-size: 25px;
      width: 100%;
      border-collapse: collapse;

      .hide-on-mobile {
        @media (max-width: 768px) {
          display: none;
        }
      }

      tbody tr {
        &:hover,
        &:focus {
          background-color: var(--light-navy);
        }
      }

      tbody tr th {
        font-weight: normal;
      }

      th,
      td {
        padding: 10px;
        text-align: left;

        &:first-child {
          padding-left: 20px;

          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }
        &:last-child {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      tr {
        
        cursor: default;

        td:first-child {
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        td:last-child {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }

      td {
        &.year {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
            font-size: var(--fz-sm);
          }
        }

        &.title {
          padding-top: 15px;
          padding-right: 20px;
          color: var(--lightest-slate);
          font-size: var(--fz-xl);
          font-weight: 600;
          line-height: 1.25;
        }

        &.company {
          font-size: var(--fz-lg);
          white-space: nowrap;
        }

        &.tech {
          font-size: var(--fz-xxs);
          font-family: var(--font-mono);
          line-height: 1.5;
          .separator {
            margin: 0 5px;
          }
          span {
            display: inline-block;
          }
        }

        &.links {
          min-width: 100px;

          div {
            display: flex;
            align-items: center;

            a {
              ${({ theme }) => theme.mixins.flexCenter};
              flex-shrink: 0;
            }

            a + a {
              margin-left: 10px;
            }
          }
        }
      }
    }
  `;

  const StyledAboutSection = styled.section`

  counter-reset: section;

  max-width: 1400px;

  h2 b {
    color: #005f91;
  }

  h2, h3 {
    color: #006ba3;
  }

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .numbered-heading {
    color: #005f91;
    font-size: 42px;
    font-weight: bold;

    &:before {
      color: #005f91;
      counter-increment: section 1;
      content: '0' counter(section) '.';
      font-size: clamp(var(--fz-md), 3vw, 24px);
      font-weight: bold;
    }

    &:after {
      background-color: #005f91;
      width: 400px;
    }
  }

  
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  width: 700px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }
    }


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid #005f91;
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

  const ArchivePage = ({ location, data }) => {
    const projects = data.allMarkdownRemark.edges;
    const revealTitle = useRef(null);
    const revealTable = useRef(null);
    const revealProjects = useRef([]);
    const prefersReducedMotion = usePrefersReducedMotion();

    useEffect(() => {
      if (prefersReducedMotion) {
        return;
      }

      sr.reveal(revealTitle.current, srConfig());
      sr.reveal(revealTable.current, srConfig(200, 0));
      revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
    }, []);

    return (
      <Layout location={location}>
        <Helmet title="Archive" />

        <main>
          <header ref={revealTitle}>
            <h1 className="big-heading" style={{color: "#005f91"}}>Aeyesafe</h1>
            <p className="subtitle" style={{fontSize:"22px", color: "#006ba3"}}>Design the B2B Management Website for a AI Senior Monitoring System.</p>
          </header>

          <StyledTableContainer ref={revealTable}>
            <table style={{color: "#006ba3"}}>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Department</th>
                  <th className="hide-on-mobile">My Role</th>
                  <th className="hide-on-mobile">Duration</th>
                  <th>Project Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2022</th>
                  <th> UI/UX Web Design Team</th>
                  <th> UI/UX Design <br/> UX Research</th>
                  <th>Oct. 2022 - Present</th>
                  <th>Ongoing internship project <br/> @ AeyeSafe</th>
                </tr>
              </tbody>
            </table>
          </StyledTableContainer>

          <h2 style={{fontSize: "30px", textAlign: "center", color: "#005f91"}}><b>Due to the NDA, only the portion of research and design I have done that are already been published or approved by the my supervisor are shared below. *</b></h2>

          <StyledAboutSection id="about" ref={revealTable}>

        
        
        <h2 className="numbered-heading">Overview</h2>
        
        <div className="inner">
          <StyledText>
            <div>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>About Aeyesafe</b></h2>

            


              <h2>
              Aeyesafe is an award-winning AI monitoring system that enhances senior safety.
              <br/><br/>
              Aeyesafe uses Ai sensors to monitor senior’s behavior, and alert caregivers by any mobile network reducing the risk of an accident or health problem and improving recovery chances while protecting their privacy.
              </h2>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/aeyesafe/cover.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/>


        <h2 style={{fontSize: "33px"}}><b>Our Challenge</b></h2>

        <br/>

        <h2>
        How might we build an website that allow caregivers to manage the senior, devices, AI sensors, and view the report dashboard in a quick and clear way? 
        </h2>

        <br/><br/>

        <h2 style={{fontSize: "33px"}}><b>Our Goal</b></h2>

        <br/>

        <h2>
        Design a B2B responsive website that support the AI monitoring system.
        </h2>

        <br/><br/>

        <h2 style={{fontSize: "33px"}}><b>Team Work and Contributions - Ongoing </b></h2>

        <br/>

        <h2>
        As a UX/UI design intern, I work with cross-platform teams of product designers, 
        product managers, and front-end engineers, where the design solutions included B2B and B2C responsive websites 
        and app design development. As part of the team, My responsibilities include: <br/><br/>
        ⦿ conduct secondary research on senior care and competitive analysis <br/>
        ⦿ design and develop low-fidelity & high-fidelity wireframes <br/>
        ⦿ present and coordinate the design with other UX designers and UX engineers <br/>
        </h2>


        <br/><br/><br/>

        {/* Section 02 - Primary Research */}

        <h2 className="numbered-heading" >Primary Research</h2>

        <div className="inner">

        <StyledPic style={{width: "700px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/aeyesafe/competitor.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledText>
          
          <br/>

            <div>

              <h2 style={{fontSize: "33px"}}><b>Competitor Analysis</b></h2>

              <h2 style={{fontSize: "28px"}}> We conducted varies competitive analysis on the senior care products in the market, including both the wearable and installable device, and the companies that utilize AI sensors to monitor senior health.</h2>
              
              <br/>

              <h2 style={{fontSize: "28px"}}><b>Indirect Competitor: </b>
              
              <br/>
              - Senior Safety
              <br/>
              - Elderly
              <br/>
              - Fitbit
              <br/>
              - Apple Watch
              <br/>
              - Wholesale
              <br/>
              - Care Penguin
              </h2>

              <br/>

              <h2 style={{fontSize: "28px"}}><b>Direct Competitor:</b>
              <br/>
              - SentryTell
              <br/>
              - Caregiver Smart Solutions
              </h2>

            </div>

          </StyledText>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - Ideation */}

        <h2 className="numbered-heading" >Ideation</h2>

        <h2 style={{fontSize: "38px", textAlign: "center"}}><b>Aeyesafe Business Mission</b></h2>

        <br/>

        <div style={{justifyContent: "center", textAlign: "center", display: "flex"}} className="inner">

        <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>No Wearable Devices & Preserved User Privacy</b></h2>

              <br/>
              
              <h2> Unlike conventional safety devices which are worn as a pendant, Aeyesafe monitors an individual from a distance indoors using AI sensors technology. </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>24/7 Human-like Observations</b></h2>

              <br/>

              <h2>With the artificial intelligent sensors, Aeyesafe provides the closest to human-like ability of a monitoring system, giving seniors safety, privacy and dignity. </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Artificial Intelligence-based Behavioral Analysis</b></h2>

              <br/>

              <h2>Aeyesafe's implemented solution keeps a behavioral history of the residence, providing real-time records and predictions of potential risks.</h2>

            </div>
          </StyledText>

        </div>

        <br/><br/><br/><br/><br/><br/>

        <div className="inner">
          
          <StyledText>
            <div>

              <br/>

              <h2 style={{fontSize: "38px"}}><b>Persona</b></h2>

              <br/>

              <h2> Our Web UI/UX Team focus on working the Aeyesafe 2B portal which allows caregivers to track the residents data and generate the reports. 
              </h2>

            </div>

          </StyledText>

          <StyledPic style={{width: "700px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/aeyesafe/persona1.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/><br/><br/>

        <h2 style={{fontSize: "33px", textAlign: "center"}}><b>
        &#91; Due to the NDA and agreement with the supervisor, 
                I will be only showcasing the <br/> following design that are finished implementations and approved by my supervisor. &#93;</b></h2>

        <br/><br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Information Architecture</b></h2>

        <br/>

        <h2 style={{textAlign: "center"}}>I am working on building the Aeyesafe 2B residents portal for caregivers to <br/> manage the resident profiles,  device settings, alert settings and activity reports. </h2>

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

          <StyledPic style={{width: "1300px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/aeyesafe/ia.png"
                width={1300}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 05 - Design */}

        <h2 className="numbered-heading" >The Design</h2>

        <br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Hi-Fi Prototype</b></h2>

        <br/><br/>

        {/* Feature 1 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f1.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
            <br/><br/><br/><br/><br/>

              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b> Residents Portal </b></h2>
                <br/>
                <h2> Caregivers can view the residents portals with an overview of the resident information and alert status. </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 2 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/><br/><br/>
              <div>
              
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Add Resident</b></h2>
                <br/>
                <h2> Caregivers can add the residents and fill in the information in four steps. </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f2.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/>

        {/* Feature 3 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f3.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/><br/><br/><br/><br/>
              
              <div>
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Basic Profile</b></h2>
                <br/>
                <h2> Caregivers can add the residents and fill in the information in four steps. </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 4 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Devices</b></h2>
                <br/>
                <h2> Caregivers can edit the device and sensor information in the Devices section. </h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f4.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/>

        {/* Feature 5 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f5.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/><br/><br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Alert Settings</b></h2>
                <br/>
                <h2> Caregivers can view the alerts and edit the settings in the Alert Settings section. </h2>
              </div>
            </StyledText>
            
        </div>

        <br/><br/><br/>

        {/* Feature 6 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Alert Settings (Continued)</b></h2>
                <br/>
                <h2> For each alert, caregivers can edit the detailed settings as in the demonstration. </h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f6.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/>

        {/* Feature 7 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/f7.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/><br/><br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Residents Portal: Activity Report</b></h2>
                <br/>
                <h2> Caregivers can view the resident’s activity summary efficiently with the data visualization in the Activity Report section. </h2>
              </div>
            </StyledText>
            
        </div>  

        <br/><br/><br/><br/><br/>

        <h2 className="numbered-heading" >Work in Progress</h2>

        <h2 style={{fontSize: "30px"}}> ⦿ The Alert Ticketing System which tracks the numbers of alerts and alert types, 
          as well as report generated for each individual residents for cargviers to check </h2>

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

            <StyledPic style={{width: "600px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/work1.png"
                  width={600}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/aeyesafe/work2.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "30px"}}>⦿ The Sleep Report Portal which shows the sleep summary report for individual residents</h2>

        <br/>
        
        <h2 style={{fontSize: "30px"}}> ⦿ The Activity Report Portal which summaries the posture and location for individual residents </h2>

        <br/>

        <h2 style={{fontSize: "30px"}}> ⦿ The Aeyesafe sensor installation process </h2>

        <br/>         
        
      </StyledAboutSection>
        </main>
      </Layout>
    );
  };
  ArchivePage.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  export default ArchivePage;

  export const pageQuery = graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              tech
              github
              external
              company
            }
            html
          }
        }
      }
    }
  `;
